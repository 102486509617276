import React from 'react';

export const Logo = ({size}) => {
  let imgSize = "22px";
  
  switch(size) {
    case "small":
      imgSize = "18px";
      break;
    case "medium":
      imgSize = "22px";
      break;
    case "large":
      imgSize = "33px";
      break;
    default:
      imgSize = "22px";
  }
  
  return (
    <img 
      src="/logo_s.png" 
      alt="Logo" 
      style={{width: imgSize, height: imgSize}}
    />
  );
};

export default Logo;