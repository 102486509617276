import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '../components/ui/Card';
import { Select, SelectItem } from '../components/ui/Select';
import { Button } from '../components/ui/Button';
import { functions } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { getFirestore, collection, doc, onSnapshot, addDoc, getDoc, setDoc} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Logo } from '../components/common/Logo';
import { Loader } from '../components/common/Loader';

const DOMAIN = process.env.REACT_APP_DOMAIN || window.location.origin;
const CREDIT_PRICE_ID = process.env.REACT_APP_CREDIT_PRICE_ID;
const CREDITS_PER_PURCHASE = 20 * 60; // 20 hours in minutes

const languageOptions = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  pt: 'Portuguese',
  nl: 'Dutch',
  da: 'Danish'
};

export const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fileId } = useParams();
  const auth = getAuth();
  const db = getFirestore();
  
  const [fileDetails, setFileDetails] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [loading, setLoading] = useState(true);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [duration, setDuration] = useState(null);
  const [price, setPrice] = useState(null);
  const [userCredits, setUserCredits] = useState(0);
  const [creditsNeeded, setCreditsNeeded] = useState(0);

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const getUserCredits = httpsCallable(functions, 'get_user_credits');
        const result = await getUserCredits();
        
        if (result.data.success) {
          setUserCredits(result.data.credits);
        } else {
          console.warn('Could not fetch user credits:', result.data.error);
          setUserCredits(0);
        }
      } catch (error) {
        console.error('Error fetching user credits:', error);
        setUserCredits(0);
      }
    };

    fetchUserCredits();
  }, []);

  useEffect(() => {
    const initializePage = async () => {
      try {
        if (location.state?.fileData) {
          setFileDetails(location.state.fileData);
        } else {
          const getFileDetails = httpsCallable(functions, 'get_file_details');
          const result = await getFileDetails({ 
            fileId: `uploads/${fileId}`
          });
          setFileDetails(result.data);
        }
      } catch (error) {
        console.error('Error initializing page:', error);
        setError('Error loading file details. Please try uploading again.');
      } finally {
        setLoading(false);
      }
    };

    initializePage();
  }, [fileId, location.state]);

  useEffect(() => {
    const calculatePricing = async () => {
      if (fileDetails) {
        try {
          console.log('Starting price calculation for transcript:', fileDetails.transcriptId);
          
          // First check if duration and price already exist in Firestore
          const transcriptRef = doc(db, 'transcripts', fileDetails.transcriptId);
          const transcriptDoc = await getDoc(transcriptRef);
          
          if (transcriptDoc.exists()) {
            const data = transcriptDoc.data();
            console.log('Found existing transcript document:', data);
            
            if (data.duration && data.price) {
              console.log('Using existing duration and price');
              setDuration(data.duration);
              setPrice(data.price);
              setCreditsNeeded(Math.max(Math.floor(data.duration / 3600), 1));
              setError(null);
              return;
            }
          }

          // If not found, proceed with calculation
          let retryCount = 0;
          const maxRetries = 3;
          const retryDelay = 1000; // 1 second
      
          const attemptCalculation = async () => {
            try {
              console.log('Calculating price via cloud function');
              const calculatePrice = httpsCallable(functions, 'calculate_duration_and_price');
              const result = await calculatePrice({ 
                transcriptId: fileDetails.transcriptId
              });
              
              if (!result.data.success) {
                throw new Error(result.data.error || 'Failed to calculate price');
              }
              
              console.log('Calculation successful:', result.data);
              
              setDuration(result.data.duration);
              setPrice(result.data.price);
              setCreditsNeeded(Math.max(Math.floor(result.data.duration / 3600), 1));
              setError(null);

              // Store the calculated values in Firestore
              try {
                console.log('Storing calculated values in Firestore');
                await setDoc(transcriptRef, {
                  duration: result.data.duration,
                  price: result.data.price,
                  calculatedAt: new Date(),
                  transcriptId: fileDetails.transcriptId
                }, { merge: true }); // Use merge option to update existing doc or create new one
                console.log('Successfully stored values in Firestore');
              } catch (firestoreError) {
                console.error('Firestore storage error:', firestoreError);
                // Don't throw error here - we still have the values in state
              }
              
            } catch (error) {
              console.error('Error in price calculation:', error);
              
              if (error.message?.includes('409') && retryCount < maxRetries) {
                retryCount++;
                console.log(`Retrying calculation (attempt ${retryCount}/${maxRetries})...`);
                await new Promise(resolve => setTimeout(resolve, retryDelay));
                return attemptCalculation();
              }
              
              setError(
                'Error calculating price. ' + 
                (retryCount > 0 ? 'Please try refreshing the page. ' : '') +
                (error.message || 'Please try again.')
              );
            }
          };
      
          await attemptCalculation();
        } catch (error) {
          console.error('Error in overall price calculation process:', error);
          setError('Error calculating price. Please try again.');
        }
      }
    };    

    calculatePricing();
  }, [fileDetails, db]);

  const createCheckoutSession = async (payload) => {
    try {
      const userRef = doc(db, 'customers', auth.currentUser.uid);
      const checkoutRef = collection(userRef, 'checkout_sessions');
      
      const sessionDoc = await addDoc(checkoutRef, {
        ...payload,
        success_url: `${DOMAIN}/transcript/${fileDetails.transcriptId}?session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${DOMAIN}/payment/${fileId}?canceled=true`,
        automatic_tax: { enabled: true },
        tax_id_collection: { enabled: true },
        payment_intent_data: {
          capture_method: 'manual',
        }
      });

      const unsubscribe = onSnapshot(sessionDoc, (snap) => {
        const { error, url } = snap.data() || {};
        if (error) {
          setError(`Payment error: ${error.message}`);
          setPaymentProcessing(false);
        }
        if (url) {
          window.location.assign(url);
        }
      });

      setTimeout(() => unsubscribe(), 120000);
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError('Error initiating payment. Please try again.');
      setPaymentProcessing(false);
    }
  };

  const handleSinglePayment = async () => {
    setPaymentProcessing(true);
    try {
      await createCheckoutSession({
        mode: 'payment',
        line_items: [{
          price_data: {
            currency: 'eur',
            unit_amount: Math.round(price * 100),
            product_data: {
              name: 'Audio Transcription Service',
            },
          },
          quantity: 1,
        }],
        metadata: {
          transcript_id: fileDetails.transcriptId,
          totext_user_id: auth.currentUser.uid,
          payment_method_types: 'cash',
          language: selectedLanguage
        },
      });
    } catch (error) {
      console.error('Payment error:', error);
      setError('Error processing payment. Please try again.');
      setPaymentProcessing(false);
    }
  };

  const handleCreditUse = async () => {
    setPaymentProcessing(true);
    try {
      const handlePayment = httpsCallable(functions, 'handle_payment');
      const result = await handlePayment({
        operation: 'spend_credits',
        transcriptId: fileDetails.transcriptId,
        creditsNeeded: creditsNeeded,
        language: selectedLanguage
      });

      if (result.data.success) {
        navigate(`/transcript/${fileDetails.transcriptId}`);
      } else {
        throw new Error('Credit usage failed');
      }
    } catch (error) {
      console.error('Error using credits:', error);
      setError('Error using credits. Please try again.');
    } finally {
      setPaymentProcessing(false);
    }
  };

  const handleBuyCredits = async () => {
    setPaymentProcessing(true);
    try {
      await createCheckoutSession({
        mode: 'payment',
        line_items: [{
          price: CREDIT_PRICE_ID,
          quantity: 1,
        }],
        metadata: {
          credit_purchase: 'true',
          credits_amount: CREDITS_PER_PURCHASE,
          transcript_id: fileDetails.transcriptId,
          totext_user_id: auth.currentUser.uid,
          payment_method_types: 'cash',
          language: selectedLanguage
        }
      });
    } catch (error) {
      console.error('Error initiating credit purchase:', error);
      setError('Error initiating credit purchase. Please try again.');
      setPaymentProcessing(false);
    }
  };

  if (loading || paymentProcessing) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <Card>
          <CardContent>
            <div className="flex flex-col items-center justify-center h-64 space-y-4">
              <Logo size="large" />
              <Loader size="large" />
              <p className="text-gray-600">
                {loading ? 'Loading file details...' : 'Processing payment...'}
              </p>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <Card>
          <CardContent>
            <div className="text-red-500 text-center p-4">
              <p>{error}</p>
              <Button
                onClick={() => navigate('/upload')}
                className="mt-4"
              >
                Return to Upload
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (!fileDetails) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <Card>
          <CardContent>
            <div className="text-center p-4">
              <p>No file details found. Please try uploading again.</p>
              <Button
                onClick={() => navigate('/upload')}
                className="mt-4"
              >
                Return to Upload
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <Card>
        <CardHeader>
          <h2 className="text-2xl font-bold">Transcription Details</h2>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="border rounded-lg p-4">
              <div className="flex items-center space-x-4">
                <div className="bg-gray-100 p-2 rounded-lg">
                  <span className="material-icons">description</span>
                </div>
                <div>
                  <p className="font-medium">{fileDetails.filename}</p>
                  {duration && (
                    <p className="text-sm text-gray-500">
                      Duration: {Math.floor(duration / 60)}m {duration % 60}s
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="border rounded-lg p-4">
              <label className="block text-sm font-medium mb-2">Language</label>
              <Select value={selectedLanguage} onChange={setSelectedLanguage}>
                {Object.entries(languageOptions).map(([code, name]) => (
                  <SelectItem key={code} value={code}>{name}</SelectItem>
                ))}
              </Select>
            </div>
          </div>

          <div className="mb-4 text-sm text-gray-600">
            {userCredits > 0 && (
              <p>Available Credits: {userCredits}</p>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Button
              onClick={handleSinglePayment}
              disabled={paymentProcessing}
              className="w-full"
            >
              Single Payment (€{price?.toFixed(2) || '...'})
            </Button>
            
            {userCredits >= creditsNeeded ? (
              <Button
                onClick={handleCreditUse}
                disabled={paymentProcessing}
                className="w-full"
              >
                Use {creditsNeeded} credit(s)
              </Button>
            ) : (
              <Button
                onClick={handleBuyCredits}
                disabled={paymentProcessing}
                className="w-full"
              >
                Buy Credits
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default PaymentPage;