import React, { useState } from 'react';
import {Container, Typography, Box, IconButton, InputAdornment, Divider} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { AuthContainer, StyledTextField, StyledButton, GoogleButton, LogoContainer } from './authStyles';

export const CustomSignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setError('');
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignIn = async () => {
    if (!email || !password) {
      setError('Please fill in all fields');
      return;
    }
    
    try {
      setLoading(true);
      setError('');
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ 
      pt: -40,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }}>
      <AuthContainer>
        <Box sx={{ width: '100%' }}>
          <LogoContainer>
            <img src="/logo.png" alt="ToText Logo" />
            <Typography variant="body2" color="text.secondary">
              Welcome back
            </Typography>
          </LogoContainer>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
            <GoogleButton
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              disabled={loading}
            >
              Continue with Google
            </GoogleButton>

            <Divider>or</Divider>

            <StyledTextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              error={!!error}
            />

            <StyledTextField
              fullWidth
              label="Password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              error={!!error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {error && (
              <Typography color="error" variant="body2" sx={{ textAlign: 'center' }}>
                {error}
              </Typography>
            )}

            <StyledButton
              fullWidth
              variant="contained"
              onClick={handleEmailSignIn}
              disabled={loading}
            >
              {loading ? 'Signing in...' : 'Continue'}
            </StyledButton>

            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              Don't have an account? {' '}
              <Link to="/sign-up" style={{ color: 'inherit' }}>
                Sign up
              </Link>
            </Typography>
          </Box>
        </Box>
      </AuthContainer>
    </Container>
  );
};