import React from 'react';

export const Button = ({ 
  children, 
  onClick, 
  disabled = false, 
  className = '',
  type = 'button'
}) => (
  <button
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={`px-4 py-2 rounded-md bg-primary text-white disabled:opacity-50 ${className}`}
  >
    {children}
  </button>
);