import React, { useState, useRef } from 'react';
import { useTheme } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/firebase';
import { Loader } from '../common/Loader';

const ALLOWED_FILE_TYPES = ['mp3', 'wav', 'ogg', 'm4a', 'wma', 'aac', 'flac', 'mp4', 'avi', 'mov', 'mkv', 'webm'];
const MAX_FILE_SIZE = 1024 * 1024 * 1024; // 1GB in bytes

const FileUploader = ({ onUploadComplete }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const theme = useTheme();

  const containerStyle = {
    width: '100%',
    height: '210px',
    padding: '50px 0px 0px 0px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: isDragging ? `2px dashed ${theme.palette.primary.main}` : 'none',
    transition: 'border 0.3s ease',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const circleStyle = {
    width: '75px',
    height: '75px',
    borderRadius: '50%',
    backgroundColor: '#f9c0f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const plusStyle = {
    color: 'black',
    fontSize: '100px',
    fontWeight: '100',
    fontFamily: 'Gill Sans',
    lineHeight: '10px',
  };

  const textStyle = {
    color: '#262730',
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '16px',
  };

  const errorStyle = {
    color: 'red',
    textAlign: 'center',
  };

  const validateAndUploadFile = async (file) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (!fileExtension || !ALLOWED_FILE_TYPES.includes(fileExtension)) {
      setError('Invalid file type. Please select an allowed file type.');
      return;
    }
    if (file.size > MAX_FILE_SIZE) {
      setError('File size exceeds 1GB limit.');
      return;
    }
  
    setIsProcessing(true);
    setError(null);
  
    try {
      console.log('Starting upload process for:', file.name);
  
      // Get signed URL using Cloud Function
      const getSignedUrl = httpsCallable(functions, 'generate_signed_url');
      console.log('Requesting signed URL...');
      
      const { data } = await getSignedUrl({ fileName: file.name });
      console.log('Received signed URL response:', data);
  
      if (!data.signedUrl) {
        throw new Error('No signed URL received');
      }
  
      // Upload file using signed URL
      console.log('Uploading file to storage...');
      const uploadResponse = await fetch(data.signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      });
  
      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.statusText}`);
      }
  
      console.log('File upload completed successfully');
  
      // Generate transcript ID
      const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
      const transcriptId = `${timestamp}_${file.name.replace(/[^a-zA-Z0-9]/g, '')}`;
  
      // Process the uploaded file
      const processFile = httpsCallable(functions, 'process_uploaded_file');
      const processResult = await processFile({
        tempBlobName: data.fileId,
        originalFilename: file.name,
        transcriptId: transcriptId
      });
  
      if (!processResult.data.success) {
        throw new Error('File processing failed');
      }
  
      console.log('File processing completed successfully');
  
      // Call onUploadComplete with additional processing info
      onUploadComplete({
        fileId: data.fileId,
        filename: file.name,
        contentType: file.type,
        transcriptId: transcriptId,
        gcsUri: processResult.data.gcsUri
      });
  
    } catch (error) {
      console.error('Upload error details:', error);
      setError(error.message || 'Error uploading file');
    } finally {
      setIsProcessing(false);
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      validateAndUploadFile(files[0]);
    }
  };

  const onFileSelect = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      validateAndUploadFile(file);
    }
  };

  return (
    <div 
      style={containerStyle}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div style={contentStyle}>
        {!isProcessing ? (
          <>
            <input
              type="file"
              onChange={onFileSelect}
              style={{ display: 'none' }}
              ref={fileInputRef}
              accept={ALLOWED_FILE_TYPES.map(type => `.${type}`).join(',')}
            />
            <div
              style={circleStyle}
              onClick={() => fileInputRef.current?.click()}
            >
              <span style={plusStyle}>+</span>
            </div>
            <p style={textStyle}>
              <strong>Upload audio or video file</strong>
            </p>
          </>
        ) : (
          <Loader size="medium" />
        )}
        {error && <p style={errorStyle}>{error}</p>}
      </div>
    </div>
  );
};

export default FileUploader;