import React from 'react';
import { useNavigate } from 'react-router-dom';
import FileUploader from '../components/upload/FileUploader';

export const UploadPage = () => {
  const navigate = useNavigate();

  const handleUploadComplete = (fileData) => {
    // Remove the 'uploads/' prefix from fileId if present
    const cleanFileId = fileData.fileId.replace('uploads/', '');
    
    // Navigate to payment with state data
    navigate(`/payment/${cleanFileId}`, {
      state: {
        fileData: {
          ...fileData,
          fileId: cleanFileId // Use the cleaned fileId in the state as well
        }
      }
    });
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <FileUploader onUploadComplete={handleUploadComplete} />        
        <div className="mt-6 text-center text-sm text-gray-600">
        </div>
      </div>
    </div>
  );
};