import './App.css';
import firebaseConfig from "./config/firebaseConfig.json";
import authMethods from "./config/authMethods.json";
import { 
  pathnames, 
  AppTemplate, 
  AuthProvider, 
  AuthRoutes, 
  MainMenu, 
  PublicTemplate, 
  UserMenu,
  UserProfile,
  UserUpdateEmail,
  UserUpdateName,
  UserUpdatePassword,
  UserDelete,
  FireactProvider 
} from '@fireactjs/core';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UploadPage } from './pages/UploadPage';
import { PaymentPage } from './pages/PaymentPage';
import { EditTranscriptPage } from './pages/EditTranscriptPage';
import { CustomSignIn } from './components/auth/CustomSignIn';
import { CustomSignUp } from './components/auth/CustomSignUp';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './config/theme';
import { Logo } from './components/common/Logo';
import { Loader } from './components/common/Loader';


// Custom Menu items for our app
const CustomMainMenu = () => {
  const menuItems = [
    <MainMenu customItems={[
      // Add your custom menu items here
    ]} />
  ];
  return menuItems;
};

function App() {
  // Add custom pathnames for our routes
  const customPathnames = {
    ...pathnames,
    Upload: "/upload",
    Payment: "/payment/:fileId", // Simplified route pattern
    EditTranscript: "/transcript/:id",
  };
  
  // Make sure the route is defined exactly as:
  <Route exact path={customPathnames.Payment} element={<PaymentPage />} />


  const config = {
    firebaseConfig: firebaseConfig,
    brand: "",
    pathnames: customPathnames,
    authProviders: authMethods
  };

  return (
    <ThemeProvider theme={theme}>
      <FireactProvider config={config}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<AuthRoutes loader={<Loader size="large" />} />} >
                <Route element={
                  <AppTemplate 
                    logo={<Logo size="large" />}
                    toolBarMenu={<UserMenu />} 
                    drawerMenu={<CustomMainMenu />} 
                  />
                }>
                  {/* Our custom routes */}
                  <Route exact path="/" element={<UploadPage />} />
                  <Route exact path={customPathnames.Upload} element={<UploadPage />} />
                  <Route exact path={customPathnames.Payment} element={<PaymentPage />} />
                  <Route exact path={customPathnames.EditTranscript} element={<EditTranscriptPage />} />
                  
                  {/* Built-in user profile routes */}
                  <Route exact path={pathnames.UserProfile} element={<UserProfile />} />
                  <Route exact path={pathnames.UserUpdateEmail} element={<UserUpdateEmail />} />
                  <Route exact path={pathnames.UserUpdateName} element={<UserUpdateName />} />
                  <Route exact path={pathnames.UserUpdatePassword} element={<UserUpdatePassword />} />
                  <Route exact path={pathnames.UserDelete} element={<UserDelete />} />
                </Route>
              </Route>
              <Route element={<PublicTemplate />}>
                <Route 
                  path={pathnames.SignIn} 
                  element={<CustomSignIn logo={<Logo size="large" />} />} 
                />
                <Route 
                  path={pathnames.SignUp} 
                  element={<CustomSignUp logo={<Logo size="large" />} />} 
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </FireactProvider>
    </ThemeProvider>
  );
}

export default App;