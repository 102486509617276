import React from 'react';

export const Select = ({ value, onChange, children, className = '' }) => (
  <select 
    value={value} 
    onChange={(e) => onChange(e.target.value)}
    className={`w-full p-2 border rounded-md ${className}`}
  >
    {children}
  </select>
);

export const SelectContent = ({ children }) => <>{children}</>;
export const SelectItem = ({ value, children }) => (
  <option value={value}>{children}</option>
);
export const SelectTrigger = ({ children }) => <>{children}</>;
export const SelectValue = () => null;
