import { styled } from '@mui/material';
import { Paper, Button, TextField } from '@mui/material';

// Main container for auth forms
export const AuthContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffffde',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.5)',
  padding: '20px',
  maxWidth: '320px',
  width: '100%',
  minWidth: '300px',
  minHeight: '380px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  margin: 'auto',
}));

// Styled text field with consistent focus colors
export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.primary.main,
  }
}));

// Primary action button
export const StyledButton = styled(Button)(({ theme }) => ({
  padding: '12px 16px',
  textTransform: 'none',
  fontSize: '1rem',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}));

// Google sign in button
export const GoogleButton = styled(Button)(({ theme }) => ({
  padding: '12px 16px',
  textTransform: 'none',
  fontSize: '1rem',
  color: theme.palette.text.primary,
  backgroundColor: 'white',
  border: '1px solid #ddd',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  }
}));

// Logo container styling
export const LogoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '2rem',
  '& img': {
    width: '247px',
    height: '52px',
    marginBottom: '0.5rem'
  }
});