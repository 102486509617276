import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button, Box } from '@mui/material';

export const EditTranscriptPage = () => {
  const { id } = useParams();
  
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Edit Transcript
        </Typography>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            multiline
            rows={12}
            variant="outlined"
            placeholder="Transcript will appear here..."
            sx={{ mb: 3 }}
          />
          <Button variant="contained" color="primary">
            Save Changes
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};