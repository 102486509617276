import { createTheme } from '@mui/material';

export const primaryColor = "#f9c0f5";

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: '#fcd4f8',
      dark: '#e5aee1',
    }
  }
});