import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { primaryColor } from '../../config/theme'; // Fixed import path
import { Logo } from './Logo';

export const Loader = ({size}) => {
  let cpSize = "35px";
  switch(size) {
    case "small":
      cpSize = "30px";
      break;
    case "medium":
      cpSize = "35px";
      break;
    case "large":
      cpSize = "45px";
      break;
    default:
      cpSize = "35px";
  }
  
  return (
    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center"}}>
      <CircularProgress 
        sx={{ color: primaryColor }}
        size={cpSize} 
      />
      <div style={{position: "absolute"}}>
        <Logo size={size} />
      </div>
    </Box>
  );
};

export default Loader;